@font-face {
  src: url('../src/fonts/BalsamiqSans-Regular.ttf');
  font-family: 'Balsamiq Sans';
}

body,
input,
textarea,
button {
  margin: 0;
  font-family: 'Balsamiq Sans';
}
